<template>
    <div class="container">
        <div>
            
            <h1 v-text="header"></h1>
            <form class="form-inline">
                <input 
                    class="form-control mr-sm-2" 
                    type="search" 
                    placeholder="Buscar" 
                    aria-label="Search" 
                    v-model="search"
                >
            </form>
        </div>

        <div v-if="loading">
            <Loader />
        </div>
        <div v-else>
            <RecipesListVertical :recipes="filteredList" />
        </div>

        <div v-if="fetchMoreLoading">
            <Loader />
        </div>
        <div v-else>
            <div class="container" v-show="seeMoreRecipes">
                <button class="btn btn-danger" @click="fetchMoreRecipes()">Ver Más Recetas</button>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/store.js';
import Loader from '@/components/Loader';
import RecipesListVertical from '@/components/lists/RecipesListVertical';
export default {
    data() {
        return {
            header: '',
            recipes: [],
            offset: 0,
            seeMoreRecipes: false,
            endpoint: '',
            search: '',
            loading: false,
            showDate: false,
            fetchMoreLoading: false,
        }
    },
    mounted() {
        window.scrollTo(0,0);
        store.isNavOpen = false;

        if (this.$route.name == 'RecetasNuevas') {
            this.header = 'Recetas Nuevas';
            this.showDate = !this.showDate;
            this.endpoint = `${this.$apiUrl}/wprm_recipe?per_page=10`;
        } else if (this.$route.name == 'RecetasPopulares') {
            this.header = 'Recetas Populares';
            this.endpoint = `${this.$apiUrlPopular}?term_id=-256,-244&limit=25`;
        }

        switch(this.$route.params.slug) {
            case 'ingredientes':
                this.fetchHeader(`wprm_ingredient/${this.$route.params.id}`);
                this.endpoint = `${this.$apiUrl}/wprm_recipe?wprm_ingredient=${this.$route.params.id}`;
                break;
            case 'colecciones':
                this.fetchHeader(`tags/${this.$route.params.id}`);
                this.endpoint = `${this.$apiUrl}/posts?tags=${this.$route.params.id}`;
                break;
            case 'cocteles':
                this.fetchHeader(`wprm_recipe/${this.$route.params.id}`);
                this.endpoint = (this.$route.params.endpoint) ? `${this.$apiUrl}/${this.$route.params.endpoint}` : `${this.$apiUrl}/wprm_recipe/${this.$route.params.id}`;
                break;
            case 'licor':
                this.fetchHeader(`categories/${this.$route.params.id}`);
                this.endpoint = `${this.$apiUrl}/posts?categories=${this.$route.params.id}`;
                break;
            case 'pais':
                this.header = this.$route.params.header;
                this.endpoint = `${this.$apiUrl}/wprm_recipe?wprm_cuisine=${this.$route.params.id}`;
                break;
            case 'buscar':
                this.endpoint = `${this.$apiUrl}/${this.$route.params.endpoint}`;
                this.header = this.$route.params.header;
                break;
        }

        this.getRecipes();
    },
    methods: {
        async fetchHeader(taxid) {
            this.header = await fetch(`${this.$apiUrl}/${taxid}`)
                .then(response => response.json())
                .then(data => { 
                    const header = data.name.charAt(0).toUpperCase() + data.name.slice(1);
                    return header;
                });
        },
        getRecipes() {
            this.whatIsLoading();
            fetch(this.endpoint)
                .then(response => response.json())
                .then(data => {
                    data.map((data) => { 
                        const dateObj = new Date(data.date);
                        data.recipe.date = dateObj.getUTCDate() + "/" + (dateObj.getUTCMonth() + 1) + "/" + dateObj.getUTCFullYear();
                        data.recipe.showDate = this.showDate;
                        data.recipe.showOz = false;
                        if (data.category) {
                            data.recipe.category = data.category;
                        }
                        this.recipes.push(data.recipe);
                    });
                    this.seeMoreRecipes = (data.length == 10) ? true : false;
                })
                .finally(() => { this.whatIsLoading(); });
        },
        fetchMoreRecipes() {
            this.offset += 10;
            this.endpoint += `&offset=${this.offset}`;
            this.getRecipes();
        },
        whatIsLoading() {
            if (this.offset == 0) {
                this.loading = !this.loading;
            } else {
                this.fetchMoreLoading = !this.fetchMoreLoading;
            }
        },
    },
    computed: {
        filteredList: function() {
            var self = this;
            return this.recipes.filter(function(recipe) {
                return recipe.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
            });
        }
    },
    components: {Loader, RecipesListVertical}
}
</script>

<style scoped>
.fas.fa-chevron-left {
    position: fixed;
    right: 90%;
    top: 30px;
    font-size: 30px;
    z-index: 1;
}
.form-inline {
    display: block;
    margin: 20px 0;
}
</style>