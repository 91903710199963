<template>
    <div class="container">
        <h1 v-text="header"></h1>

        <p v-show="addFaves">Tus cócteles favoritos aparecerán aquí para que puedas accederlos rápidamente.</p>
        <p v-show="addFaves">Simplemente toque el ícono de estrella <i class="far fa-star"></i> en cualquier receta para comenzar a guardarlas.</p>

        <div v-if="loading">
            <Loader />
        </div>
        <div v-else>
            <RecipesListVertical :recipes="favRecipes" />
        </div>

    </div>
</template>

<script>
import { store } from '@/store.js';
import Loader from '@/components/Loader';
import RecipesListVertical from '@/components/lists/RecipesListVertical';
export default {
    name: 'Favorites',
    data() {
        return {
            header: 'Mis Favoritos',
            faves: [],
            favRecipes: [],
            addFaves: false,
            loading: false,
        }
    },
    mounted() {
        store.isNavOpen = false;

        if (localStorage.getItem('recipes')) {
            try {
                this.faves = JSON.parse(localStorage.getItem('recipes'));
            } catch(e) {
                localStorage.removeItem('recipes');
            }
        }

        if (this.faves.length > 0) {
            this.getFaveRecipes();
        } else {
            this.addFaves = !this.addFaves;
        }
    },
    methods: {
        getFaveRecipes() {
            this.loading = !this.loading;
            const requests = this.faves.map(async fave => {
                await fetch(`${this.$apiUrl}/wprm_recipe/${fave}`)
                    .then(response => response.json())
                    .then(data => {
                        data.recipe.category = data.category;
                        this.favRecipes.push(data.recipe) 
                    })
            })

            return Promise.all(requests)
                .finally(() => { 
                    // alphabetize the list
                    this.favRecipes = this.sortedList(this.favRecipes);
                    this.loading = !this.loading; 
                })
        },
        sortedList(list) {
            function compare(a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }
            return list.sort(compare);
        },
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? true : false;
        },
    },
    components: {RecipesListVertical, Loader}
}
</script>

<style scoped>
.recipes-list {
    list-style: none;
    padding: 0;
}
.recipes-list .recipe {
    margin-bottom: 30px;
}
.badge-primary {
    margin: 0 5px 0 0;
}
.list-recipes-list .media {
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: left;
}
.list-recipes-list .media img {
  width: 175px;
}
.fas.fa-chevron-left {
    position: fixed;
    right: 90%;
    top: 30px;
    font-size: 30px;
    z-index: 1;
}
.form-inline {
    display: block;
    margin: 20px 0;
}
.media-body h2 {
    font-size: 22px;
}
@media screen and (min-width: 767px) {
    .list-recipes-list .media img {
        width: 350px;
    }
}
</style>